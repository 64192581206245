.i-h{
    margin-top: 100vh;
    margin-left: 140px;
    position: relative;
}

.i-left-wrap{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro{
    font-size: 30px;
    font-weight: 300;
}

.i-name{
    font-size: 60px;
}

.i-title{
    height: 100px;
    overflow: hidden;
}

.i-title-wrap{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
}

.i-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(255, 149, 0);
    display: flex;
    align-items: center;
}

@media screen and (max-width: 650px){
    .i-h {
        flex-direction: column;
        margin-left: 0px;
    }
    .i-left-wrap{
        height: 100%;
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .i-left{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5vh;
    }

    .i-title{
        height: 100px;
        overflow: hidden;
    }

    .i-desc{
        display: none;
    }
}
.prod{
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgb(243,242,242);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}

.p-browser{
    height: 20px;
    background-color: rgb(243,242,242);
    display: flex;
    align-items: center;
}

.p-circle{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
}

.p-img{
    width: 100%;
    transition: all 10s ease;
}

.prod:hover .p-img{
    transform: translateY(-100%);
}

@media screen and (max-width: 650px){
    .prod{
        width: 40%;
        height: 20vh;
    }
}
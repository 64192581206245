.c{
    /* height: 100vh; */
    margin: 70px;
    margin-top: 100px;
    padding-bottom: 100px;
    position: relative;
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;

}

.c-bg{
    width: 20px;
    height: 100%;
    position: relative;
    bottom: 0px;
    left: 0px;
    background-color: rgb(255, 149, 0);
    position: absolute;
}

.c-wrapper{
    padding: 50px;
    display: flex;
}

.c-left{
    flex: 1
}

.c-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title{
    font-size: 60px;
    width: 80%;
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0;
    font-weight: 300;
    width: 70%;
}

.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc{
    font-weight: 200;
}

form{
    margin-top: 20px;
    height: 50%;
}

input{
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

/* button{
    border: none;
    padding: 15px;
    background-color: rgb(3, 140, 252);
    color: white;
    font-weight: 500;
} */

button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: rgb(255, 149, 0);
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  
  button:hover {background-color: rgb(3, 140, 252)}
  
  button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  @media screen and (max-width: 650px){
    .c{
        /* height: 100vh; */
        margin: 0;
        margin-top: 100px;
        padding-bottom: 100px;
        position: relative;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
    
    }

    .c-wrapper{
        flex-direction: column;
        padding: 0 50px;
    }

    .c-title{
        font: 30px;
    }

    .c-info-item{
        margin: 20px 0px;
        width: 100%;
    }

    .c-desc{
        display: none;
    }

    form{
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
    }

    input{
        width: 35%;
        height: 20px;
        margin: 20px;
        margin-left: 0;
    }

    button{
        margin-top: 10px;
    }
}
.i{
    position: relative;
}

.i-right{
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.i-img{
    width: 70vw;
    height: 70vh;
    object-fit: cover;
}

.i-bg{
    clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.build-wrap{
    position: absolute;
    right: 0;
    top: 0;
}

.build-group{
    width: 500px;
    height: 920px;
    background-color: bisque;
    position: relative;
}

.build{
    background-color: black;
    position: relative;
    width: 25%;
}

#b1{
    height: 50%;
    position: absolute;
    bottom: 0;
}

#b2{
    height: 65%;
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: 999;
}

#b3{
    height: 85%;
    position: absolute;
    bottom: 0;
    right: 25%;
}

#b4{
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0px;
}

.i-h {
    flex-direction: column;
}


.i-title{
    height: 100px;
    overflow: hidden;
}

.i-desc{
    display: none;
}

@media screen and (max-width: 400px){
    .i-img{
        width: 100%;
        height: 70vh;
    }
}

@media screen and (max-width: 650px){
    .i {
        flex-direction: column;
    }


    
}

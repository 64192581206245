.a{
    /* height: 100vh; */
    display: flex;
}

.a-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right{
    flex:1;
    margin-right: 140px;
}

/* .a-card.bg{
    position: absolute;
    top: 450px;
    left: 350px;
    background-color: rgb(3, 140, 252);
} */

.a-card{
    width: 60%;
    /* height: 70vh; */
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    
}

.img-card{
    margin-right: 40px;
}

.a-award-img{
    width: 240px;
    height: 400px;
    border-radius: 20px;
    margin-right: 30px;
}

.a-title{
    font-weight: 400;
}

.a-sub{
    margin:20px 0px;
}

.a-desc{
    font-weight: 300;
}

.a-award{
    margin-top: 50px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
}

.a-awrd-texts{
    width: 70%;
}

.a-award-title{
    font-weight: bold;
    color: #555;
}

.a-award-img-mob{
    display: none;
}

@media screen and (max-width: 400px){
    .a-award-img-mob{
        visibility: visible;
        width: 200px;
        height: 380px;
        border-radius: 20px;
        margin-right: 30px;
    }

    .a-award-img{
        display: none;

    }
    
}

@media screen and (max-width: 650px){
    .a{
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
    }

    .a-left{
        width: 100%;
    }

    .a-right{
        margin-right: 0px;
        padding: 30px;

    }

    .a-card-bg {display: none;}
}
